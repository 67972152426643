import Vue from 'vue';
import { localize } from 'vee-validate';
import axios from 'axios';
import extend from 'lodash/extend';

export default {
    async loadErrorResources(lang: string) {
        const settings = Vue.$culture.getSettings(lang);
        let dictionary = {
            name: lang,
            messages: {}
        };

        try {
            dictionary = await import(`vee-validate/dist/locale/${settings.validator || settings.culture}.json`);
        } catch (e) {
            console.log(e);
        }

        try {
            const instance = axios.create({
                baseURL: process.env.BASE_URL
                    ? process.env.BASE_URL
                    : '/'
            });

            const response = await instance.get(`/locales/${settings.culture}/errors.json`);
            const errors = response.data || {};

            extend(dictionary.messages, errors);
        } catch (e) {
            console.log(e);
        }

        localize(lang, dictionary);
    }
};
