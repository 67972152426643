import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import Otp from '../otp';
import { OtpStatus } from '../../constants';

@Component({
    data() {
        return {
            Status: OtpStatus,
            showModal: false
        };
    },
    components: {
        Otp
    }
})
export default class OtpModal extends Vue {
    @Prop(Number)
    callbackStatus: number;

    @Prop()
    mobile: string;

    @Prop()
    countryCode: string;

    @Prop(Boolean)
    value: boolean;

    @Prop(Boolean)
    isInvalid: boolean;

    @Prop(Boolean)
    otpLimitReached: boolean;

    @Prop(Number)
    timestamp: number;

    @Prop(Boolean)
    twoFactorOtp: boolean;

    showModal: boolean;

    mounted() {
        this.showModal = this.value;
    }

    @Watch('value')
    onChangeValue(value: boolean) {
        this.showModal = value;
    }

    @Watch('showModal')
    onChangeOtpEnabled(value: boolean) {
        this.$emit('input', value);
    }

    async handleOtpValidation(code: string) {
        this.$emit('validate', code);
    }

    async resendOtp() {
        this.$emit('resend');
    }
}
