import { Model } from '@vuex-orm/core';

export default class Navigation extends Model {
    static entity = 'navigation';

    static fields() {
        return {
            id: this.attr(null),
            lang: this.string(''),
            dateFormat: this.string('')
        };
    }
}
