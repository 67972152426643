import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import LanguageMenu from '@/components/language-menu';

@Component({
    data() {
        return {
            showModal: false
        };
    },
    components: {
        LanguageMenu
    }
})
export default class PasswordNotification extends Vue {
    @Prop(Boolean)
    value: boolean;

    @Prop()
    email: string;

    showModal: boolean;

    mounted() {
        this.showModal = this.value;
    }

    @Watch('value')
    onChangeValue(value: boolean) {
        this.showModal = value;
    }

    @Watch('showModal')
    onChangeShowModal(value: boolean) {
        this.$emit('input', value);
    }
}
