import Service from '@/core/services/service';
import { ServicePromise } from '@/core/services/interfaces';
import { authenticationIntentIds } from '@/constants/authentication-intent-ids';
import { authenticationModeIds } from '@/constants/authentication-mode-ids';
import api from './config';

export default {
    getConfig(returnUrl: string): ServicePromise<any> {
        const services = new Service(api);
        const url = 'config';

        const requestConfig = {
            params: {
                returnUrl
            }
        };

        return services.getData(url, requestConfig);
    },

    getResetPasswordTokenInfo(token: string): ServicePromise<any> {
        const services = new Service(api);
        const url = `account/v2/resetpasswordtoken/${token}`;

        return services.getData(url);
    },

    login(data: any): ServicePromise<any> {
        const services = new Service(api);
        const url = 'account/v2/login';

        return services.postData(url, data);
    },

    resetPassword(data: any): ServicePromise<any> {
        const services = new Service(api);
        const url = 'account/v2/resetpassword';

        return services.postData(url, data);
    },

    resetPasswordToken(data: any): ServicePromise<any> {
        const services = new Service(api);
        const url = 'account/v2/resetpasswordtoken';

        return services.postData(url, data);
    },

    requestOtp(data: any): ServicePromise<any> {
        const services = new Service(api);
        const url = 'account/otp';

        return services.postData(url, data);
    },

    requestTwoFa(data: any): ServicePromise<any> {
        const services = new Service(api);
        const url = 'account/login/2fa';

        return services.postData(url, data);
    },

    createSession(data: {
        recaptcha: string,
        intent: authenticationIntentIds,
        modeId?: authenticationModeIds
    }): ServicePromise<any> {
        const services = new Service(api);
        const url = 'account/sessions';

        return services.postData(url, data);
    },

    getDetails(): ServicePromise<any> {
        const services = new Service(api);
        const url = 'account/details';

        return services.getData(url);
    },

    hasClientWithEmail(email: string, token: string) {
        const services = new Service(api);
        const url = 'account/v2/exists';

        const data = {
            email,
            recaptchaToken: token
        };

        return services.postData(url, data);
    },

    createClient(data) {
        const services = new Service(api);
        const url = 'account/v2/register';

        return services.postData(url, data);
    }
};
