import { render, staticRenderFns } from "./autocomplete-field.vue?vue&type=template&id=96ea5bb8"
import script from "./autocomplete-field.ts?vue&type=script&lang=js&external"
export * from "./autocomplete-field.ts?vue&type=script&lang=js&external"
import style0 from "./autocomplete-field.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
