import { render, staticRenderFns } from "./two-factor.vue?vue&type=template&id=c55e9d90&scoped=true"
import script from "./two-factor.ts?vue&type=script&lang=js&external"
export * from "./two-factor.ts?vue&type=script&lang=js&external"
import style0 from "./two-factor.scss?vue&type=style&index=0&id=c55e9d90&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c55e9d90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { PageModal as VivaAppPageModal } from '@/components'
installComponents(component, {VBtn,VivaAppPageModal})
