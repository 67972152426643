import * as i18n from './i18n';

export const StateOptions = {
    vuex: 'vuex-registration',
    duration: {
        day: 15
    }
};

export const NavigationOptions = {
    vuex: 'vuex-navigation',
    navigationIndex: 1,
    navigationState: 'vuex-navigation',
    duration: {
        day: 100
    }
};

export default {
    i18n
};
