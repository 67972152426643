import { render, staticRenderFns } from "./otp.vue?vue&type=template&id=02d7bad0&scoped=true"
import script from "./otp.ts?vue&type=script&lang=js&external"
export * from "./otp.ts?vue&type=script&lang=js&external"
import style0 from "./otp.scss?vue&type=style&index=0&id=02d7bad0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02d7bad0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
installComponents(component, {VOtpInput})
