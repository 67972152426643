import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import {
    PLATFORMS,
    POS_PLATFORMS,
    PLATFORM_INDEX,
    DEFAULT_PLATFORM,
    MOBILE_PLATFORMS,
    DESKTOP_PLATFORM,
    POS_IOS_PLATFORM,
    POS_ANDROID_PLATFORM
} from '@/constants/platforms';

@Component({
    data() {
        return {
            isNativeApp: false
        };
    }
})
export default class Platform extends Vue {
    isNativeApp: boolean;

    get platform() {
        let platform = Vue.$sessionStorage.getItem('platform');

        if (this.$route.query.platform) {
            platform = this.$route.query.platform as string;
        }

        return platform && this.isValidPlatform(platform)
            ? platform
            : DEFAULT_PLATFORM;
    }

    get platformIndex() {
        return PLATFORM_INDEX[this.platform] || PLATFORM_INDEX[DEFAULT_PLATFORM];
    }

    setPlatform(platform?: string | null) {
        if (!platform) {
            platform = this.$route.query.platform as string;
        }

        if (this.isValidPlatform(platform)) {
            Vue.$sessionStorage.setItem('platform', platform);
        }
    }

    isValidPlatform(platform: string) {
        return PLATFORMS.indexOf(platform) > -1;
    }

    get isMobilePlatform() {
        return MOBILE_PLATFORMS.indexOf(this.platform) > -1;
    }

    get isPosPlatform() {
        return POS_PLATFORMS.indexOf(this.platform) > -1;
    }

    get isWebView() {
        const isNativeApp = Vue.$localStorage.getJsonItem('isNativeApp');

        return this.isMobilePlatform || this.isPosPlatform || isNativeApp;
    }

    get isPosIosPlatform() {
        return this.platform === POS_IOS_PLATFORM;
    }

    get isPosAndroidPlatform() {
        return this.platform === POS_ANDROID_PLATFORM;
    }

    get isDesktopPlatform() {
        return this.platform === DESKTOP_PLATFORM;
    }
}
