import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { FieldMixin } from '../mixins';

@Component({
    data() {
        return {
            item: false
        };
    }
})
export default class Checkbox extends mixins(FieldMixin) {
    @Prop([String, Number, Boolean])
    value: string | number | boolean;

    @Prop(Boolean)
    required: boolean;

    item: string | number | boolean;

    @Prop({ default: 'default' })
    theme: string;

    created() {
        this.item = this.value;
    }

    @Watch('value')
    onChangeValue(value) {
        this.item = value;
    }

    @Watch('item')
    onChangeItem(value) {
        this.$emit('input', value);
    }
}
