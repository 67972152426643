import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import { MonitorRuleStatus } from './monitor-rule-status';

@Component({
    data() {
        return {
            status: MonitorRuleStatus.Neutral
        };
    }
})
export default class MonitorRule extends Vue {
    @Prop(RegExp)
    rule: RegExp;

    @Prop(Boolean)
    tracking: boolean;

    @Prop({default: ''})
    content: string;

    @Prop(Boolean)
    value: boolean;

    status: MonitorRuleStatus;

    created() {
        if (this.content && this.value) {
            this.applyRule();
        }
    }

    @Watch('content')
    onChangeValue() {
        this.applyRule();
    }

    @Watch('tracking')
    onChangeTrack(value, oldValue) {
        if (oldValue || this.content === '') {
            this.applyRule();
        }
    }

    @Watch('status')
    onChangeStatus(value) {
        this.$emit('input', value === MonitorRuleStatus.Valid);
    }

    applyRule() {
        if (this.tracking) {
            this.status = (this.rule.test(this.content) && this.content !== '')
                ? MonitorRuleStatus.Valid
                : MonitorRuleStatus.Invalid;
        } else {
            this.status = MonitorRuleStatus.Neutral;
        }
    }

    get wrapperClass() {
        switch (this.status) {
            case MonitorRuleStatus.Valid:
                return 'valid';
            case MonitorRuleStatus.Invalid:
                return 'invalid';
            default:
                return 'neutral';
        }
    }

    get ruleIcon() {
        switch (this.status) {
            case MonitorRuleStatus.Valid:
                return '$app-check-circle';
            case MonitorRuleStatus.Invalid:
                return '$app-close-circle';
            default:
                return '$app-circle';
        }
    }
}
