import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { i18n } from '@/core/i18n';

import LanguageMenu from '@/components/language-menu';
import Platform from '@/mixins/platform';
import PageLogo from '../page-logo';
import PreviousStepButton from '../../../previous-step-button';

@Component({
    data() {
        return {
            languages: null
        };
    },
    components: {
        PageLogo,
        LanguageMenu,
        PreviousStepButton
    }
})
export default class PageContent extends mixins(i18n.Mixins.Culture, Platform) {
    @Prop()
    title: string;

    @Prop()
    subtitle: string;

    @Prop()
    heroTitle: string;

    @Prop()
    previousBtnText: string;

    @Prop(Boolean)
    previousBtnIcon: boolean;

    @Prop(Boolean)
    nativeApp: boolean;

    @Prop(Boolean)
    hideLogo: boolean;

    languages;

    async mounted() {
        this.languages = this.getSortedLanguages();
    }
}
