import bundle from '@/utilities/bundle';
import { Url } from '@/core/helpers';

export const INVITATION_ROOT_PATH = '/invite/:invitationType/:invitationId';

export const INVITATION_CLIENT_START_ROUTE = {
    path: '/',
    name: 'invite-client-start',
    component: bundle.loadView('invite/start/start'),
    props: true,
    meta: {
        title: 'clientStart'
    }
};

export const INVITATION_CLIENT_DETAILS_ROUTE = {
    path: '/details',
    name: 'invite-client-details',
    component: bundle.loadView('invite/details/details'),
    props: true,
    meta: {
        title: 'clientDetails'
    }
};

export const INVITATION_CLIENT_AUTH_ROUTE = {
    path: '/auth',
    name: 'invite-client-auth',
    component: bundle.loadView('invite/auth/auth'),
    props: true
};

export const INVITATION_CLIENT_CONNECT_ROUTE = {
    path: '/connect',
    name: 'invite-client-connect',
    component: bundle.loadView('invite/connect/connect'),
    props: true
};

export const REGISTER_CLIENT_ROUTES = {
    path: Url.join('/account', INVITATION_ROOT_PATH),
    children: [
        INVITATION_CLIENT_START_ROUTE,
        INVITATION_CLIENT_DETAILS_ROUTE,
        INVITATION_CLIENT_AUTH_ROUTE,
        INVITATION_CLIENT_CONNECT_ROUTE
    ]
};
