import Vue from 'vue';
import VueRouter from 'vue-router';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';

import { i18n } from '@/core/i18n';
import routes from '@/routes';
import { store } from '@/store';
import VueLocalStorage from '@/plugins/localstorage';
import VueSessionStorage from '@/plugins/sessionstorage';
import App from '@/views/app';
import Constants from '@/constants';
import { AppVuetify as vuetify } from '@/theme';
import { Url } from '@/core/helpers';
import {
    LOCALE_ALIAS,
    LOCALE_SETTINGS,
    COUNTRY_LANGUAGE
} from '@/core/i18n/constants';
import { setInteractionMode, localize } from 'vee-validate';

import '@/validations';

setInteractionMode('lazy');

Vue.use(VueI18Next);

const vueI18Next = new VueI18Next(i18next);

Vue.use(i18n.Plugins.VueCulture, {
    id: 'accounts',
    defaultLang: Constants.i18n.DEFAULT_LANG,
    localeSettings: LOCALE_SETTINGS,
    localeAlias: LOCALE_ALIAS,
    countryLanguage: COUNTRY_LANGUAGE,
    i18nextOptions: {
        lng: Constants.i18n.LOCALIZE_OPTIONS.culture,
        fallbackLng: Constants.i18n.LOCALIZE_OPTIONS.culture,
        ns: ['common', 'login', 'countries', 'invitation'],
        backend: {
            loadPath: Url.join(
                process.env.BASE_URL,
                Constants.i18n.LOCALIZE_OPTIONS.path
            ),
            queryStringParams: { v: process.env.VUE_APP_CACHE_VERSION }
        }
    }
});

localize(Constants.i18n.DEFAULT_LANG);

Vue.use(VueLocalStorage);
Vue.use(VueSessionStorage);
Vue.use(VueRouter);

Vue.config.devtools = true;

const router = new VueRouter({
    mode: 'history',
    routes
});

new Vue({
    router,
    vuetify,
    store: store(),
    data: {},
    i18n: vueI18Next,
    render: (h) => h(App)
}).$mount('#app-root');
