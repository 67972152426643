import { render, staticRenderFns } from "./reset-password.vue?vue&type=template&id=b07c8e6e"
import script from "./reset-password.ts?vue&type=script&lang=ts&external"
export * from "./reset-password.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { CookiesBlockedAlert as VivaAppCookiesBlockedAlert } from '@/components'
import { Page as VivaAppPage } from '@/components'
import { AutocompleteField as VivaAutocompleteField } from '@/core/components'
import { Loader as VivaLoader } from '@/core/components'
import { TextField as VivaTextField } from '@/core/components'
installComponents(component, {VivaAppCookiesBlockedAlert,VivaAppPage,VivaAutocompleteField,VivaLoader,VivaTextField})
