import { Model } from '@vuex-orm/core';

export default class User extends Model {
    static entity = 'user';

    static fields() {
        return {
            username: this.string(''),
            mobileNumber: this.string(''),
            mobilePrefix: this.number(''),
            password: this.string(''),
            email: this.string(''),
            countryCode: this.string(''),
            rememberMe: this.boolean(false)
        };
    }
}
