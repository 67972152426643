import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import HttpStatus from 'http-status-codes';

import OtpModal from '@/components/otp/components/otp-modal';
import NextStepButton from '@/components/next-step-button';
import TwoFactor from '@/components/two-factor/two-factor.vue';
import { authenticationModeIds } from '@/constants/authentication-mode-ids';
import { TwoFactorMethodType } from '@/constants/two-factor';
import User from '@/store/models/user';
import { i18n } from '@/core/i18n';
import { Url } from '@/core/helpers';
import { OtpStatus } from '@/components/otp/constants';
import FormWrapperInstance from '@/core/fields/form-wrapper/form-wrapper';
import EventId from '@/services/event-id';
import { IdentityService } from '@/services/identity-service';
import { getToken } from '@/utilities/grecaptcha';
import { RecaptchaAction } from '@/constants/recaptcha-action';
import { SsoProvider } from '@/constants/sso-provider';
import { IovationField } from '@/core/iovation/components';
import { getDeviceToken } from '@/core/iovation/utilities';
import Platform from '@/mixins/platform';
import { ServerEnvironment } from '@/constants/server-environment';
import { DEVICE_ENROLLMENT_START_ROUTE } from '@/routes/device-enrollment';
import {
    matchCountryByLocalName,
    matchCountryByPrefix
} from '@/core/countries/helpers/country';

@Component({
    data() {
        return {
            user: null,
            loading: true,
            processing: false,
            countries: [],
            otpLimitReached: false,
            isInvalid: false,
            loginTimeout: 0,
            twoFactorOtp: false,
            returnUrl: '',
            promptDeviceEnrollment: false,
            isUsernameFocused: false,
            failedSSO: false,
            ssoProvider: SsoProvider
        };
    },
    components: {
        OtpModal,
        TwoFactor,
        IovationField,
        NextStepButton
    }
})
export default class Login extends mixins(
    i18n.Mixins.Culture,
    Platform
) {
    @Ref() form!: InstanceType<typeof FormWrapperInstance>;
    @Ref() twoFactorDialog!: any;

    @Prop(Number)
    serverEnvironment: ServerEnvironment;

    user;
    error;
    loading: boolean;
    processing: boolean;
    countries: Array<any>;
    otpEnabled: boolean = false;
    otpLimitReached: boolean;
    createStatus: OtpStatus = OtpStatus.None;
    isInvalid: boolean;
    loginTimeout: number;
    twoFactorOtp: boolean;
    returnUrl: string;
    promptDeviceEnrollment: boolean;
    isUsernameFocused: boolean;
    failedSSO: boolean;

    async mounted() {
        this.loading = true;

        this.user = new User();
        this.user.mobileNumber = Vue.$localStorage.getItem('username');

        this.countries = Vue.$localStorage.getJsonItem('countriesPrefix') || [];
        this.returnUrl = Vue.$localStorage.getJsonItem('returnUrl') || '';
        this.isNativeApp = Vue.$localStorage.getJsonItem('isNativeApp');

        const ssoResult = JSON.parse(Vue.$sessionStorage.getItem('ssoResult') || '{}');

        window.addEventListener('beforeunload', () => {
            Vue.$sessionStorage.removeItem('ssoResult');
        });

        this.setUserCountryCode();
        this.setPlatform();

        this.loading = false;

        this.$nextTick(() => {
            if (this.isTwoFaSessionInProgress() || this.isOtpSessionInProgress()) {
                this.loginAuthenticationMethodSwitch(this.getModeId());
            } else {
                this.isUsernameFocused = true;
            }
        });

        if (isEmpty(ssoResult)) {
            return;
        }

        if (!ssoResult.success) {
            if (ssoResult.eventId === EventId.SecurityCreateOtpConflict) {
                this.otpEnabled = true;

                const otpDate = Vue.$localStorage.getItem(this.otpCreatedAtKey);

                if (otpDate) {
                    this.user.requestTimestamp = +otpDate;
                }

                return;
            }

            this.failedSSO = !ssoResult.success && !ssoResult.data;

            return;
        }

        Vue.$localStorage.setItem('timestamp', String(dayjs(new Date()).valueOf()));
        Vue.$localStorage.setItem('otpCountryCode', ssoResult.data.accountCountryCode);
        Vue.$localStorage.setItem('otpMobile', ssoResult.data.accountUsername);
        Vue.$localStorage.setItem('promptDeviceEnrollment', ssoResult.data.promptDeviceEnrollment);

        if (ssoResult.data.modeId === authenticationModeIds.PushNotification) {
            Vue.$localStorage.setItem(String(TwoFactorMethodType.Push), JSON.stringify(ssoResult.data));
        }

        this.loginTimeout = ssoResult.data.duration;

        this.promptDeviceEnrollment = ssoResult.data.promptDeviceEnrollment;

        await this.loginAuthenticationMethodSwitch(ssoResult.data.modeId);
    }

    customFilter(item, queryText, itemText) {
        const localizedCountryName = this.$t(`countries:${item.code}.name`);

        return matchCountryByLocalName(localizedCountryName, queryText) ||
            matchCountryByPrefix(item, queryText);
    }

    get pageTitle() {
        if (this.failedSSO) {
            return this.$t('login:emailNotConnected');
        }

        return this.$t('login:welcomeBack');
    }

    get pageSubtitle() {
        if (this.failedSSO) {
            return this.$t('login:enterPhoneAndPassword');
        }

        return this.$t('login:connectToViva');
    }

    get otpCreatedAtKey() {
        return `otpCreated-${this.user?.mobileNumber}`;
    }

    get otpMobile() {
        const ssoResult = JSON.parse(Vue.$sessionStorage.getItem('ssoResult') || '{}');

        if (!isEmpty(ssoResult)) {
            const ssoCountryCode = Vue.$localStorage.getItem('otpCountryCode');
            const ssoPrefix = this.countries.find((country) => country.code === ssoCountryCode)?.prefix;
            const ssoMobile = Vue.$localStorage.getItem('otpMobile') as string;

            if (ssoMobile && ssoCountryCode) {
                const ssoMobileTrimmed = ssoMobile.replace(ssoPrefix, '');

                return `+${ssoPrefix} ${ssoMobileTrimmed}`;
            }

            return '';
        }

        if (this.user?.mobileNumber) {
            return `+${this.prefix} ${this.user.mobileNumber}`;
        }

        return `+${this.prefix} ${Vue.$localStorage.getItem('otpMobile')}`;
    }

    setUserCountryCode() {
        const countryCode = this.countries.find(
            (country) => country.code.toLowerCase() === Vue.$localStorage.getItem('countryCode')?.toLowerCase()
        )?.code;

        this.user.countryCode = countryCode || this.countries[0].code;
    }

    get prefix() {
        if (!this.user?.countryCode) {
            return '';
        }

        const selected = this.countries.find((country) => country.code === this.user.countryCode);

        return selected ? selected.prefix : '';
    }

    get isProductionServerEnvironment() {
        return this.serverEnvironment === ServerEnvironment.Production;
    }

    async handleOtpValidation(otp: string) {
        this.createStatus = OtpStatus.Pending;

        if (!otp) {
            return;
        }

        const result = await IdentityService.requestTwoFa({
            oneTimePassword: otp,
            rememberMe: this.user?.rememberMe
        });

        if (!result.success) {
            Vue.$localStorage.removeItem(String(TwoFactorMethodType.Push));

            this.processing = false;

            if (result.status === HttpStatus.FORBIDDEN) {
                this.isInvalid = true;
                this.createStatus = OtpStatus.InvalidOtp;
            }

            if (result.data.eventId === EventId.AccountsLocked) {
                this.error = result.data;
                this.closeModal();
            }

            return;
        }

        this.createStatus = OtpStatus.Success;

        if (this.otpCreatedAtKey) {
            Vue.$localStorage.removeItem(this.otpCreatedAtKey);
        }

        Vue.$localStorage.removeItem(String(TwoFactorMethodType.Push));

        this.error = {};

        if (JSON.parse(Vue.$localStorage.getItem('promptDeviceEnrollment') as any)) {
            this.promptDeviceEnrollment = true;
        }

        await this.loginRedirect();
    }

    async resendOtp() {
        this.createStatus = OtpStatus.Pending;

        this.user.requestTimestamp = dayjs(new Date()).valueOf();
        Vue.$localStorage.setItem(this.otpCreatedAtKey, `${this.user.requestTimestamp}`);

        const tokenResult = await getToken(config.googleRecaptchaSiteKeyId);

        if (!tokenResult.success) {
            this.createStatus = OtpStatus.None;

            return;
        }

        const result = await IdentityService.requestOtp({
            username: this.user.mobileNumber ? this.user.mobileNumber : Vue.$localStorage.getItem('otpMobile'),
            countryCode: this.user.countryCode,
            language: this.getLang(),
            token: tokenResult.data.token
        });

        this.createStatus = OtpStatus.None;
    }

    closeModal() {
        this.createStatus = OtpStatus.None;
        this.otpEnabled = false;

        if (this.getModeId() === authenticationModeIds.OneTimePassword) {
            Vue.$localStorage.removeItem(String(TwoFactorMethodType.Push));
        }
    }

    showCookieBlockedAlert() {
        return !window.navigator.cookieEnabled;
    }

    @Watch('user.mobileNumber')
    watchMobileNumber(value) {
        if (/^[0-9]{5,15}$/.test(value)) {
            window.sessionStorage.setItem('mobileNumber', value);
        } else {
            window.sessionStorage.removeItem('mobileNumber');
        }
    }

    @Watch('user.countryCode')
    watchCountryCode(value) {
        Vue.$geoLocator.setCountryCode(this.user.countryCode);
    }

    async loginAuthenticationMethodSwitch(apiResponse) {
        switch (apiResponse) {
            case authenticationModeIds.None:
                await this.loginRedirect();

                break;
            case authenticationModeIds.OneTimePassword:
                this.openOtpModal();

                break;
            case authenticationModeIds.PushNotification:
                this.twoFactorDialog?.open();

                break;
        }
    }

    isTwoFaSessionInProgress() {
        return (this.getModeId() === authenticationModeIds.PushNotification) && this.isTwoFaTimeValid();
    }

    isOtpSessionInProgress() {
        return (this.getModeId() === authenticationModeIds.OneTimePassword) && this.isOtpTimeValid();
    }

    isTwoFaTimeValid() {
        return (dayjs(new Date()).valueOf() - parseInt(Vue.$localStorage.getItem('timestamp') as any)) <= this.getSessionDuration();
    }

    isOtpTimeValid() {
        return (dayjs(new Date()).valueOf() - parseInt(Vue.$localStorage.getItem('timestamp') as any)) <= this.getSessionDuration();
    }

    getModeId() {
        if (Vue.$localStorage.getItem(String(TwoFactorMethodType.Push))) {
            return JSON.parse(Vue.$localStorage.getItem(String(TwoFactorMethodType.Push)) as any).modeId;
        }

        return null;
    }

    getSessionDuration() {
        return JSON.parse(Vue.$localStorage.getItem(String(TwoFactorMethodType.Push)) as any).duration * 1000;
    }

    openOtpModal() {
        this.otpEnabled = true;

        if (this.getModeId() === authenticationModeIds.OneTimePassword) {
            this.user.requestTimestamp = parseInt(Vue.$localStorage.getItem('timestamp') as any);
        } else {
            this.user.requestTimestamp = dayjs(new Date()).valueOf();
        }

        Vue.$localStorage.setItem(this.otpCreatedAtKey, `${this.user.requestTimestamp}`);

        this.twoFactorOtp = true;
    }

    async loginRedirect() {
        Vue.$localStorage.removeItem('promptDeviceEnrollment');

        /**
         * If users has already remember me option
         * and if modeId equals with authenticationModeIds.None
         * then we redirect to Device Enrolment Campaign
         */
        if (this.promptDeviceEnrollment) {
            await this.$router.push({
                name: DEVICE_ENROLLMENT_START_ROUTE.name
            });
        } else {
            window.location.href = this.returnUrl;
        }
    }

    hasServerError() {
        return !isEmpty(this.error);
    }

    getErrorText() {
        switch (this.error.eventId) {
            case EventId.InvalidUsernamePassword:
            case EventId.AccountsPasswordValidationFailed:
                return this.$t('login:wrongCredentials');

            case EventId.AccountsLocked:
                return this.$t('login:accountLockedError');

            case EventId.AccountsInactive:
                return this.$t('login:inactiveAccount');

            default:
                return this.$t('login:generalErrorMessage');
        }
    }

    continueWithSSO(ssoProvider: SsoProvider) {
        const connectPageUrl = Url.join(process.env.BASE_URL, '/account/connect-login');

        window.location.href = `external/challenge?scheme=${ssoProvider}&returnUrl=${encodeURIComponent(connectPageUrl)}`;
    }

    async submit() {
        const formValid = await this.form?.validate();

        if (!formValid) {
            return;
        }

        const deviceToken = await getDeviceToken();

        this.error = {};

        this.processing = true;

        const tokenResult = await getToken(
            config.googleRecaptchaSiteKeyId,
            RecaptchaAction.Login
        );

        if (!tokenResult.success) {
            this.processing = false;

            return;
        }

        const result = await IdentityService.login({
            username: this.user.mobileNumber,
            password: this.user.password,
            countryCode: this.user.countryCode,
            rememberMe: this.user.rememberMe,
            returnUrl: this.returnUrl,
            language: this.getLang(),
            token: tokenResult.data.token,
            deviceToken
        });

        if (!result.success) {
            this.isInvalid = false;
            this.processing = false;

            if (result.eventId === EventId.SecurityCreateOtpConflict) {
                this.otpEnabled = true;
                const otpDate = Vue.$localStorage.getItem(this.otpCreatedAtKey);

                if (otpDate) {
                    this.user.requestTimestamp = +otpDate;
                }

                return;
            }

            if (result.status === HttpStatus.BAD_REQUEST) {
                this.error.eventId = 101;

                return;
            }

            this.error = result.data;
        }

        this.processing = false;

        Vue.$localStorage.setItem('timestamp', String(dayjs(new Date()).valueOf()));
        Vue.$localStorage.setItem('otpMobile', this.user.mobileNumber);
        Vue.$localStorage.setItem('promptDeviceEnrollment', result.data.promptDeviceEnrollment);

        if (result.data.modeId === authenticationModeIds.PushNotification) {
            Vue.$localStorage.setItem(String(TwoFactorMethodType.Push), JSON.stringify(result.data));
        }

        this.loginTimeout = result.data.duration;

        this.promptDeviceEnrollment = result.data.promptDeviceEnrollment;

        await this.loginAuthenticationMethodSwitch(result.data.modeId);
    }
}
