export const enum LOCALES {
    Bulgarian = 'bg',
    Croatian = 'hr',
    Czech = 'cs',
    Danish = 'da',
    Deutsch = 'de',
    Dutch = 'nl',
    English = 'en',
    Finnish = 'fi',
    French = 'fr',
    Greek = 'el',
    Hungarian = 'hu',
    Italian = 'it',
    Norwegian = 'nb',
    Polish = 'pl',
    Portuguese = 'pt',
    Romanian = 'ro',
    Spanish = 'es',
    Swedish = 'sv'
}

export const LOCALE_ALIAS = {
    gr: LOCALES.Greek
};
