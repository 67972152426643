export function isAndroidDevice() {
    return /(Android)/i.test(window.navigator.userAgent);
}

export function isAppleMobileDevice() {
    return /(iPhone|iPod|iPad)/i.test(window.navigator.userAgent);
}

export function isAppleDevice() {
    return /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent);
}

export function isWindowsDevice() {
    return /(Win)/i.test(window.navigator.userAgent);
}
