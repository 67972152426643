import { BrowserNames } from '@/constants/browsers';

export function identifyBrowser(browser: BrowserNames): boolean {
    switch (browser) {
        case BrowserNames.Brave:
            return /\bBrave\b/.test(navigator.userAgent);
        case BrowserNames.Chrome:
            return /^(?!.*\b(?:OPR|Edg|Brave)\b).*Chrome.*/.test(navigator.userAgent);
        case BrowserNames.Chromium:
            return /\bChrome\b/.test(navigator.userAgent);
        case BrowserNames.Edge:
            return /\bEdg\b/.test(navigator.userAgent);
        case BrowserNames.Firefox:
            return /^(?!.*\bSeamonkey\b).*Firefox.*/.test(navigator.userAgent);
        case BrowserNames.Opera:
            return /\bOPR|Opera\b/.test(navigator.userAgent);
        case BrowserNames.Safari:
            return /^(?!.*\b(?:Chrome|Chromium)\b).*Safari.*/.test(navigator.userAgent);
        case BrowserNames.Seamonkey:
            return /\bSeamonkey\b/.test(navigator.userAgent);
        default:
            return false;
    }
}
