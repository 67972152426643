import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueTheMask from 'vue-the-mask';
import Item from '@vuex-orm/core/lib/data/Item';

import '@/filters/uppercase';
import '@/scss/site.scss';
import { GeoLocation } from '@/core/geolocation';
import { Url } from '@/core/helpers';
import { setBeacon } from '@/utilities/beacon';
import Platform from '@/mixins/platform';
import Navigation from '@/store/models/navigation';
import { NavigationOptions } from '@/constants';
import { IdentityService } from '@/services/identity-service';
import { DESKTOP_PLATFORM, PLATFORM_INDEX } from '@/constants/platforms';
import { i18n } from '@/core/i18n';
import { ServerEnvironment } from '@/constants/server-environment';
import { initializeDatadog } from '@/core/datadog/datadog-rum';

Vue.use(GeoLocation.Plugins.GeoLocator, {
    storageKey: 'countryCode'
});

Vue.use(VueTheMask);

@Component({
    data() {
        return {
            loading: true,
            languages: null,
            serverEnvironment: 0
        };
    }
})
export default class App extends mixins(
    i18n.Mixins.Culture,
    Platform
) {
    loading: boolean;
    hideFooter: boolean;
    languages: Array<any>;
    serverEnvironment: ServerEnvironment;

    async mounted() {
        await this.applyConfig();

        const platform = Url.getUrlParameterByName('platform');

        this.setPlatform(platform);

        this.isNativeApp = localStorage.getItem('isNativeApp') === 'true';

        await this.setCountryCode();
        await this.importLanguage();

        this.languages = this.getSortedLanguages();

        this.setMobileNumber();
        this.setPageTitle();
        this.setPageStyle();
        this.hideFooter = this.isNativeApp;

        await initializeDatadog();

        this.loading = false;
    }

    async applyConfig() {
        const queryReturnUrl = this.$route.query.ReturnUrl;
        const { data, success } = await IdentityService.getConfig(queryReturnUrl as string);

        if (!success) {
            return;
        }

        const {
            language,
            returnUrl,
            countries,
            countryCode,
            registerUrl,
            userName,
            isNativeApp,
            platform,
            environment,
            showAccountRegistrationUrl
        } = data;

        config.registerUrl = registerUrl;

        this.serverEnvironment = environment;

        await this.showBeacon(this.serverEnvironment);

        if (language) {
            Vue.$localStorage.setItem('language', language);
        }

        if (returnUrl) {
            Vue.$localStorage.setItem('returnUrl', JSON.stringify(returnUrl));
        }

        // Sort Countries by international name
        const sortedCountries = countries.sort((a: any, b: any) => {
            if (!a.internationalName || !b.internationalName) {
                return 0;
            }

            if (a.internationalName < b.internationalName) {
                return -1;
            }

            if (a.internationalName > b.internationalName) {
                return 1;
            }

            return 0;
        });

        Vue.$localStorage.setItem('countriesPrefix', JSON.stringify(sortedCountries));

        if (countryCode) {
            Vue.$localStorage.setItem('countryCode', countryCode);
        }

        if (userName) {
            Vue.$localStorage.setItem('username', userName);
        } else {
            Vue.$localStorage.removeItem('username');
        }

        Vue.$localStorage.setItem('isNativeApp', isNativeApp);

        Vue.$localStorage.setItem('showAccountRegistrationUrl', showAccountRegistrationUrl);

        if (!Vue.$sessionStorage.getItem('platform')) {
            Vue.$sessionStorage.setItem('platform', PLATFORM_INDEX[platform] || DESKTOP_PLATFORM);
        }
    }

    get navigation(): Item {
        return this.getNavigation();
    }

    async showBeacon(environment) {
        const validEnvironment: boolean = [
            ServerEnvironment.Demo,
            ServerEnvironment.Production
        ].includes(environment);

        if (validEnvironment) {
            await setBeacon();
        }
    }

    getNavigation(): Item {
        return Navigation.find(NavigationOptions.navigationIndex);
    }

    setPageTitle() {
        const title = !this.isWebView ? ['viva.com'] : [];

        if (this.$route.meta.title) {
            const pageTitle = this.$t(`login:${this.$route.meta.title}`);

            if (pageTitle && pageTitle !== this.$route.meta.title) {
                title.push(pageTitle);
            }
        }

        document.title = title.reverse().join(' - ');
    }

    setMobileNumber() {
        const mobileNumber = this.$route.query.phoneNumber as string;

        if (mobileNumber) {
            Vue.$sessionStorage.setItem('mobileNumber', mobileNumber);
        }
    }

    async setCountryCode() {
        const countryCode = this.$route.query.countryCode as string || (await Vue.$geoLocator.getCountryCode());

        if (countryCode) {
            Vue.$localStorage.setItem('countryCode', countryCode);
        }
    }

    setPageStyle() {
        const className = ['page', `page-${this.$route.name}`];

        if (this.$route.meta.cssClass) {
            className.push(this.$route.meta.cssClass);
        }

        if (this.platform) {
            if (this.isWebView) {
                className.push('platform-webview');
            }

            className.push(`platform-${this.platform}`);
        }

        document.body.className = className.join(' ');
    }

    @Watch('navigation.lang', { deep: true })
    onChangeLanguage(value, oldValue) {
        this.setPageTitle();
    }

    @Watch('$route', { immediate: true, deep: true })
    onUrlChange() {
        this.setPageTitle();
    }
}
