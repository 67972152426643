import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Page from '../page';

@Component({
    data() {
        return {
            opened: false
        };
    },
    components: {
        Page
    }
})
export default class PageModal extends Vue {
    @Prop(Boolean)
    persistent: boolean;

    @Prop(Boolean)
    value: boolean;

    @Prop(Object)
    pageConfig: { [key: string]: any }

    opened: boolean;

    @Watch('value')
    onChangeValue(value: boolean) {
        this.opened = value;
    }

    @Watch('opened')
    onChangeOpened(value: boolean) {
        this.$emit('input', value);
    }
}
