import Login from '@/views/login';
import ConnectLogin from '@/views/connect-login';
import LoggedOut from '@/views/logged-out';
import NewPassword from '@/views/new-password';
import ResetPassword from '@/views/reset-password';
import { DEVICE_ENROLLMENT_ROUTES } from '@/routes/device-enrollment';
import { REGISTER_CLIENT_ROUTES } from '@/routes/client';

const withPrefix = (prefix, routes) => routes.map((route) => {
    route.path = prefix + route.path;

    return route;
});

const routes = [
    {
        path: '/account/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'logInToYourAccount'
        }
    },
    {
        path: '/account/connect-login',
        name: 'ConnectLogin',
        component: ConnectLogin
    },
    {
        path: '/account/reset-password',
        alias: '/account/resetpassword',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
            title: 'resetYourPassword'
        }
    },
    {
        path: '/account/reset-password/new/:verificationToken',
        name: 'new-password',
        component: NewPassword,
        meta: {
            title: 'setPassword'
        },
        props: true
    },
    {
        path: '/account/loggedout',
        name: 'logged-out',
        component: LoggedOut
    },
    ...withPrefix(DEVICE_ENROLLMENT_ROUTES.path, DEVICE_ENROLLMENT_ROUTES.children),
    ...withPrefix(REGISTER_CLIENT_ROUTES.path, REGISTER_CLIENT_ROUTES.children)
];

export default routes;
