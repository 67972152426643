import HttpStatus from 'http-status-codes';

import { CacheData, ServicePromise, ServiceResponse } from './interfaces';
import { VIVA_EVENT_ID, VIVA_CORRELATION_ID } from './constants/headers';

export const SuccessHttpStatus: Array<number> = [
    HttpStatus.OK,
    HttpStatus.NO_CONTENT
];

export const UnauthorizedHttpStatus: Array<number> = [
    HttpStatus.UNAUTHORIZED
];

export default class PromiseHelper {
    isResponseSuccess(status: number): boolean {
        return SuccessHttpStatus.indexOf(status) > -1;
    }

    isResponseUnauthorized(status: number): boolean {
        return UnauthorizedHttpStatus.indexOf(status) > -1;
    }

    failPromise(failResponse?: ServiceResponse): ServicePromise<ServiceResponse<any>> {
        const failPromise = Promise.resolve(failResponse || { invalid: true }) as ServicePromise;

        failPromise.then((response) => {
            response.success = false;

            if (response.headers) {
                response.eventId = response.headers[VIVA_EVENT_ID];
                response.correlationId = response.headers[VIVA_CORRELATION_ID];
            }
        });

        return failPromise;
    }

    successPromise(successResponse?: ServiceResponse): ServicePromise<ServiceResponse<any>> {
        const successPromise = Promise.resolve(successResponse || {}) as ServicePromise;

        successPromise.then((response) => {
            response.success = true;

            if (response.headers) {
                response.eventId = response.headers[VIVA_EVENT_ID];
                response.correlationId = response.headers[VIVA_CORRELATION_ID];
            }
        });

        return successPromise;
    }

    createPromise(data): ServicePromise<ServiceResponse<CacheData>> {
        const promise = Promise.resolve({
            data: data.data,
            status: HttpStatus.OK
        }) as ServicePromise;

        promise.then((response) => {
            response.success = true;
        });

        return promise;
    }
}
