export const COUNTRY_CODES = {
    Austria: 'AT',
    Belgium: 'BE',
    Bulgarian: 'BG',
    Croatia: 'HR',
    Cyprus: 'CY',
    CzechRepublic: 'CZ',
    Denmark: 'DK',
    Estonia: 'EE',
    Finland: 'FI',
    France: 'FR',
    Germany: 'DE',
    Guadeloupe: 'GP',
    Gibraltar: 'GI',
    GreatBritain: 'GB',
    Greek: 'GR',
    Hungary: 'HU',
    Iceland: 'IS',
    Ireland: 'IE',
    Italy: 'IT',
    Latvia: 'LV',
    Liechtenstein: 'LI',
    Lithuania: 'LT',
    Luxembourg: 'LU',
    Malta: 'MT',
    Martinique: 'MQ',
    Monaco: 'MC',
    Netherlands: 'NL',
    Norway: 'NO',
    Poland: 'PL',
    Portugal: 'PT',
    Romania: 'RO',
    Reunion: 'RE',
    Russia: 'RU',
    SanMarino: 'SM',
    Slovakia: 'SK',
    Slovenia: 'SI',
    Spain: 'ES',
    Sweden: 'SE',
    Switzerland: 'CH',
    Ukraine: 'UA'
};
