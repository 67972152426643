import { render, staticRenderFns } from "./page-skeleton.vue?vue&type=template&id=0e2875dc&scoped=true"
import script from "./page-skeleton.ts?vue&type=script&lang=js&external"
export * from "./page-skeleton.ts?vue&type=script&lang=js&external"
import style0 from "./page-skeleton.scss?vue&type=style&index=0&id=0e2875dc&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e2875dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
