import { toJson } from '@/core/parsers/toJson';

interface LocalStoragePlugin extends Storage {
    getJsonItem(key: string);
}

declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $localStorage: LocalStoragePlugin
    }
}

export default class VueLocalStorage {
    static install(Vue, options?: any) {
        Vue.$localStorage = {
            hasLocalStorage():boolean {
                return window.localStorage != null;
            },
            getItem(key: string) {
                if (!this.hasLocalStorage()) {
                    return null;
                }

                return window.localStorage.getItem(key);
            },
            getJsonItem(key: string) {
                return toJson(this.getItem(key));
            },
            setItem(key: string, value: any) {
                if (!this.hasLocalStorage()) {
                    return;
                }

                window.localStorage.setItem(key, value);
            },
            removeItem(key: string) {
                if (!this.hasLocalStorage()) {
                    return;
                }

                window.localStorage.removeItem(key);
            }
        };
    }
}
