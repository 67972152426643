export const IOS_PLATFORM = 'ios';
export const POS_IOS_PLATFORM = 'pos_ios';
export const ANDROID_PLATFORM = 'android';
export const DESKTOP_PLATFORM = 'desktop';
export const POS_ANDROID_PLATFORM = 'pos_android';

export const DEFAULT_PLATFORM = DESKTOP_PLATFORM;

export const PLATFORMS = [
    IOS_PLATFORM,
    ANDROID_PLATFORM,
    DESKTOP_PLATFORM,
    POS_IOS_PLATFORM,
    POS_ANDROID_PLATFORM
];

export const MOBILE_PLATFORMS = [
    IOS_PLATFORM,
    ANDROID_PLATFORM
];

export const POS_PLATFORMS = [
    POS_IOS_PLATFORM,
    POS_ANDROID_PLATFORM
];

export const PLATFORM_INDEX = {
    [DESKTOP_PLATFORM]: 0,
    [IOS_PLATFORM]: 1,
    [ANDROID_PLATFORM]: 2,
    [POS_IOS_PLATFORM]: 3,
    [POS_ANDROID_PLATFORM]: 4
};
