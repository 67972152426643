import { RecaptchaAction } from '@/constants/recaptcha-action';

export async function getToken(
    googleRecaptchaSiteKeyId: string,
    action: string = RecaptchaAction.Login
): Promise<any> {
    return await new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
            try {
                grecaptcha
                    .execute(googleRecaptchaSiteKeyId, { action })
                    .then(async (token) => {
                        resolve({
                            success: true,
                            data: { token }
                        });
                    }, reject);
            } catch (error) {
                resolve({
                    success: false,
                    data: {
                        message: error
                    }
                });
            }
        });
    });
}
