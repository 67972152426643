import { extend } from 'vee-validate';

import { isMobile } from '@/core/rules';

extend('mobile', {
    validate(value, { countryCode }: any) {
        return isMobile(value, countryCode);
    },
    params: ['countryCode']
});
