import { removeDiacritics } from '@/filters/uppercase';
import { Country } from '../interfaces';

export function getCountryInfo(countries: Array<Country>, countryCode: string) {
    return countries.find((country) => country.code === countryCode);
}

export function filterCountriesWithValidPrefix(countries: Array<Country>) {
    return countries.filter((country) => country.prefix !== 0);
}

export function matchCountryByLocalName(countryName: string, searchText: string) {
    const localCountryName = removeDiacritics(countryName).toLowerCase();

    return localCountryName.indexOf(removeDiacritics(searchText).toLowerCase()) > -1;
}

export function matchCountryByPrefix(country: Country, searchText: string) {
    return country.prefix
        .toString()
        .indexOf(searchText.replace('+', '')) > -1;
}
