import Service from '@/core/services/service';
import { ServicePromise } from '@/core/services/interfaces';
import api from './config';

export default {
    getUserDetails(): ServicePromise<any> {
        const services = new Service(api);
        const url = 'external/details';

        return services.getData(url);
    },

    login(data: any): ServicePromise<any> {
        const services = new Service(api);
        const url = 'external/login';

        return services.postData(url, data);
    }
};
