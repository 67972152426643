import { render, staticRenderFns } from "./text-field.vue?vue&type=template&id=6bf89274"
import script from "./text-field.ts?vue&type=script&lang=ts&external"
export * from "./text-field.ts?vue&type=script&lang=ts&external"
import style0 from "./text-field.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})
