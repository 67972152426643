import { setInteractionMode } from 'vee-validate';

setInteractionMode('lazy-enhanced', (context) => {
    if (context.value === '' && context.flags.dirty) {
        return {
            on: ['change', 'blur']
        };
    }

    if (context.flags.validated) {
        return {
            on: ['input', 'blur']
        };
    }

    if (context.flags.changed) {
        return {
            on: ['blur']
        };
    }

    return {
        on: ['change']
    };
});
