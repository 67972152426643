import * as Cookies from 'js-cookie';

import GeoLocationStorage from './geolocation-storage';

export default class GeolocationCookies implements GeoLocationStorage {
    constructor(readonly expires?: number) {}

    getItem(key: string) {
        return Cookies.get(key);
    }

    setItem(key: string, value: any) {
        Cookies.set(key, value);
    }

    removeItem(key: string) {
        Cookies.remove(key);
    }
}
