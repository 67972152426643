import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import {
    MdAppIcons
} from '../icons';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#0D55CF',
                secondary: '#3a3d46',
                anchor: '#0D55CF',
                default: '#ededee'
            }
        }
    },
    icons: {
        iconfont: 'mdiSvg',
        values: {
            ...MdAppIcons
        }
    }
});
