import { Vue, Component, Prop } from 'vue-property-decorator';

const Default = () => import('./components/default');
const Keybase = () => import('./components/keybase');

@Component
export default class Loader extends Vue {
    @Prop()
    loader: string;

    @Prop()
    loaderText: string;

    get selectedLoader() {
        switch (this.loader) {
            case 'keybase':
                return Keybase;
            default:
                return Default;
        }
    }
}
