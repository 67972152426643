import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { FieldMixin } from '../mixins';

@Component
export default class SelectField extends mixins(FieldMixin) {
    @Prop({ default: { bottom: true, offsetY: true } })
    menuProps: {};
}
