import { Model } from '@vuex-orm/core';

import { VuexOrmExtensions } from '@/core/vuex-orm-extensions';
import { RegistrationSystem } from '@/constants/registration-system';
import { InvitationAddress } from './invitation-address';

const { trim } = VuexOrmExtensions.Utilities;

export class InvitationClient extends Model {
    static entity = 'invitationClient';

    static fields() {
        return {
            id: this.attr(null),
            culture: this.attr(''),
            lang: this.attr(''),
            email: this.attr('', (value) => trim(value)),
            firstName: this.attr('', (value) => trim(value)),
            lastName: this.attr('', (value) => trim(value)),
            mobileNumber: this.attr(''),
            mobilePrefix: this.number(''),
            termsAccepted: this.boolean(false),
            consentGranted: this.boolean(false),
            new: this.boolean(true),
            address: this.morphOne(InvitationAddress, 'modelId', 'modelType'),
            registrationSystem: this.number(RegistrationSystem.Default),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }

    get countryCode() {
        return this.address.countryCode;
    }

    get mobile() {
        if (!this.mobilePrefix) {
            return this.mobileNumber;
        }

        return `+${this.mobilePrefix}${this.mobileNumber}`;
    }

    get formattedMobile() {
        if (!this.mobilePrefix) {
            return this.mobileNumber;
        }

        return `+${this.mobilePrefix} ${this.mobileNumber}`;
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
}
