import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';

import { removeDiacritics } from '@/filters/uppercase';
import { FieldMixin } from '../mixins';

interface SelectData<T> {
    data: Array<T>,
    key: string
    value: string,
    search?: string
    prefix?: string;
}

@Component({
    data() {
        return {
            optionValue: '',
            optionText: null
        };
    },
    components: {
        ValidationProvider
    }
})
export default class AutocompleteField extends mixins(FieldMixin) {
    @Ref() autocomplete!: any;

    @Prop(Object)
    options: SelectData<any>;

    @Prop({ default: '' })
    label: string;

    @Prop()
    value: string;

    @Prop(Boolean)
    disabled: boolean;

    @Prop(Boolean)
    readonly: boolean;

    @Prop()
    resource: string;

    @Prop()
    cssClass: string;

    @Prop(Boolean)
    native: boolean;

    @Prop()
    nativePlaceholder: string;

    @Prop({ default: true })
    clearable: boolean;

    selectedItem;
    optionValue;
    optionText;
    searchOptions: Array<string> = [];
    hasData: boolean = false;

    get model() {
        return this.optionValue;
    }

    set model(value) {
        this.optionValue = value;
    }

    created() {
        if (this.value) {
            this.optionValue = this.value;
        } else if (this.native &&
            this.options?.data?.length > 0) {
            if (this.nativePlaceholder) {
                this.optionValue = this.value;
            } else {
                this.optionValue = this.options.data[0][this.options.key];
            }
        }

        if (this.options.search) {
            this.searchOptions = this.options.search.split(',');
        }

        this.searchOptions.push(this.options.value);
    }

    openedOnFocus: boolean = false;
    openTimeout;

    get openOnFocus() {
        return this.openedOnFocus;
    }

    mounted() {
        let mdField;

        if (this.autocomplete) {
            mdField = this.autocomplete.$children.find((item) => typeof item.MdField === 'object');
        }

        if (!mdField) {
            return;
        }

        const mdMenu = mdField.$children.find((item) => typeof item.MdMenu === 'object');

        if (!mdMenu) {
            return;
        }

        const mdInput = mdMenu.$children.find((item) => typeof item.MdField === 'object');

        if (!mdInput) {
            return;
        }

        mdInput.onFocus = () => {
            window.clearTimeout(this.openTimeout);

            this.openTimeout = setTimeout(() => {
                if (!this.autocomplete.showMenu) {
                    this.autocomplete.showOptions();
                }
            }, 300);
        };
    }

    @Watch('options')
    initializeData(value, oldValue) {
        if (!this.hasData &&
            this.options?.data?.length > 0) {
            this.findItem();

            this.hasData = true;
        }
    }

    @Watch('options.data')
    loadData(value, oldValue) {
        if (value !== oldValue && this.options?.data?.length > 0) {
            this.findItem();

            if (this.native && !this.value && !this.nativePlaceholder) {
                this.optionValue = this.options.data[0][this.options.key];
            }
        }
    }

    findItem() {
        const match = this.options.data.filter((item, index) => item[this.options.key] === this.value);

        if (match.length > 0) {
            this.selectedItem = match[0];
            this.optionText = this.getOptionText(this.selectedItem);
        } else {
            this.optionText = '';
        }
    }

    customFilter(item, queryText, itemText) {
        return removeDiacritics(itemText)
            .toLocaleLowerCase()
            .indexOf(removeDiacritics(queryText).toLocaleLowerCase()) > -1;
    }

    filterData(data:any, term: string) {
        return this.searchOptions.find((item, index) => removeDiacritics(data[item].toLowerCase()).includes(term));
    }

    @Watch('optionText')
    clear(value, oldValue) {
        if (value === '' && this.selectedItem) {
            this.selectedItem = null;
            this.optionText = '';
            this.optionValue = '';
        }
    }

    @Watch('optionValue')
    changeSelection(value) {
        this.$emit('input', value);
    }

    @Watch('value')
    changeValue(value) {
        if (this.optionValue !== value) {
            this.optionValue = value;

            this.findItem();
        }
    }

    getOptionText(item) {
        return this.resource
            ? this.$t(`${this.resource}:${item[this.options.key]}.${this.options.value}`)
            : item[this.options.value];
    }

    selected(item) {
        this.selectedItem = item;
        this.optionText = this.getOptionText(item);
        this.optionValue = item[this.options.key];

        this.$emit('md-selected', item);
    }

    closed() {
        this.$emit('md-closed');
    }
}
