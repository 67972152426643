import { Model } from '@vuex-orm/core';

export default class UserAuth extends Model {
    static entity = 'userAuth';

    static primaryKey = 'id';

    static fields() {
        return {
            id: this.increment(),
            password: this.string(''),
            receivedOtp: this.boolean(false),
            otp: this.string(''),
            otpMobile: this.attr(null),
            modelId: this.attr(null),
            modelType: this.attr(null),
            requestTimestamp: this.number(0)
        };
    }
}
