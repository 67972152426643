import { render, staticRenderFns } from "./otp-modal.vue?vue&type=template&id=1b89f316"
import script from "./otp-modal.ts?vue&type=script&lang=js&external"
export * from "./otp-modal.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { PageModal as VivaAppPageModal } from '@/components'
installComponents(component, {VivaAppPageModal})
