export async function getDeviceToken() {
    try {
        const blackboxInfo = await (window as any).IGLOO.getBlackbox();

        return blackboxInfo.blackbox;
    } catch (e) {
        console.log(e);

        return '';
    }
}
