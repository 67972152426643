import { extend } from 'vee-validate';
import i18next from 'i18next';

import { isEmailDomainBlocked } from '@/core/rules';
import { EMAIL_BLOCKED_DOMAINS } from '@/constants/email-blocked-domains';

extend('email_domain_blocked', {
    validate(value) {
        return isEmailDomainBlocked(value, EMAIL_BLOCKED_DOMAINS);
    },
    message: (_) => (i18next.t('errors:email_domain_blocked'))
});
