import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import Platform from '@/mixins/platform';

import { i18n } from '@/core/i18n';
import LanguageMenu from '@/components/language-menu';
import NextStepButton from '../../../next-step-button';
import PreviousStepButton from '../../../previous-step-button';
import PageContent from '../page-content';

@Component({
    data() {
        return {
            languages: null
        };
    },
    components: {
        PageContent,
        LanguageMenu,
        NextStepButton,
        PreviousStepButton
    }
})
export default class Page extends mixins(i18n.Mixins.Culture, Platform) {
    @Ref() form;

    @Prop(Boolean)
    noForm: boolean;

    @Prop(Boolean)
    locked: boolean;

    @Prop(Boolean)
    loading: boolean;

    @Prop()
    nextBtnText: string | null;

    languages: Array<any>;

    async mounted() {
        this.languages = this.getSortedLanguages();

        this.isNativeApp = Vue.$localStorage.getJsonItem('isNativeApp');
    }

    async validate() {
        return this.form?.validate();
    }

    goToError() {
        return this.form?.goToError();
    }
}
