const EventId: { [key: string]: string } = {
    SecurityValidateOtpInvalid: '3717',
    SecurityCreateOtpConflict: '3720',
    AccountsInactive: '3731',
    AccountsLocked: '4900',
    InvalidUsernamePassword: '4901',
    AccountsPasswordValidationFailed: '4908',
    RegistrationCouldNotCreateOtpTooManyRequests: '12102',
    UserAccountsPersonNotFound: '12107'
};

export default EventId;
