import { Component, Vue } from 'vue-property-decorator';

import { isAppleMobileDevice, isAndroidDevice } from '@/core/helpers/devices';

@Component
export default class CookiesBlockedAlert extends Vue {
    appleDevice: boolean;
    androidDevice: boolean;

    mounted() {
        this.appleDevice = isAppleMobileDevice();
        this.androidDevice = isAndroidDevice();
    }
}
