import { render, staticRenderFns } from "./new-password.vue?vue&type=template&id=3cf7b99c&scoped=true"
import script from "./new-password.ts?vue&type=script&lang=ts&external"
export * from "./new-password.ts?vue&type=script&lang=ts&external"
import style0 from "./new-password.scss?vue&type=style&index=0&id=3cf7b99c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf7b99c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { Page as VivaAppPage } from '@/components'
import { PageModal as VivaAppPageModal } from '@/components'
import { Loader as VivaLoader } from '@/core/components'
import { PasswordField as VivaPasswordField } from '@/core/components'
installComponents(component, {VBtn,VivaAppPage,VivaAppPageModal,VivaLoader,VivaPasswordField})
