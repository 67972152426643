import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { AutofillMixin, FieldMixin } from '../mixins';

@Component({
    data() {
        return {
            unmasked: false
        };
    }
})
export default class PasswordField extends mixins(FieldMixin, AutofillMixin) {
    unmasked: boolean;
}
