import { delay } from '@/core/helpers/delay';

export async function retry(
    callback: () => void,
    retries: number = 3,
    interval: number = 1000
) {
    try {
        callback();
    } catch (error) {
        if (retries) {
            await delay(interval);

            return retry(callback, retries - 1, interval);
        }
    }

    return null;
}
