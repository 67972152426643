import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component({
    data() {
        return {
            date: dayjs(0)
        };
    }
})
export default class Countdown extends Vue {
    date;
    interval;

    @Prop({ default: 'mm:ss' })
    format: string;

    @Prop()
    millis: number;

    created() {
        this.start();
    }

    start() {
        if (this.millis <= 0) {
            return;
        }

        // Finish current counter
        if (this.date.valueOf() > 0) {
            this.finish();
        }

        this.date = dayjs(this.millis);
        this.interval = setInterval(this.calculateTime, 1000);
    }

    finish() {
        this.date = dayjs(0);
        clearInterval(this.interval);
    }

    @Watch('millis')
    onMillisChange(value, oldValue) {
        if (value !== oldValue && oldValue > 0) {
            this.start();
        }
    }

    calculateTime() {
        this.date = dayjs(this.date.subtract(1, 'seconds'));

        if (this.date.valueOf() <= 0) {
            this.finish();
            this.$emit('finished');
        }
    }

    get time() {
        return this.date.format(this.format);
    }
}
