import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: {
        ValidationProvider
    }
})
export class FieldMixin extends Vue {
    @Ref() provider!: any;

    @Prop()
    name: string;

    @Prop([Object, String, Function])
    rules: object | string | Function;

    @Prop({ default: 'lazy-enhanced' })
    validationMode: string;

    @Prop([Array, String])
    errorMessages: Array<string> | string;

    get validationIdAttr() {
        const { id, name } = this.$attrs;

        return `${id || name}-validation-message`;
    }
}
