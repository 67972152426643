import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';

import Countdown from '@/core/countdown';
import { TextField } from '@/core/fields';
import { OtpStatus } from '../../constants';

@Component({
    data() {
        return {
            validCode: false,
            Status: OtpStatus,
            isResendEnabled: true
        };
    },
    components: {
        TextField,
        Countdown
    }
})
export default class Otp extends Vue {
    @Ref() otpInputField!: any;

    code: string = '';
    isResendEnabled: boolean;

    @Prop({ type: Number, default: OtpStatus.None })
    processStatus: number;

    @Prop()
    mobile: string;

    @Prop()
    countryCode: string;

    @Prop(Boolean)
    isInvalid: boolean;

    @Prop(Boolean)
    otpLimitReached: boolean;

    @Prop(Number)
    timestamp: number;

    @Prop({ default: 600_000, type: Number })
    requestThreshold: number;

    created() {
        if (this.getRemainingTime() > 0) {
            this.disableResend();
        } else {
            this.enableResend();
        }
    }

    mounted() {
        this.$nextTick(() => this.otpInputField.focus());
    }

    get loading() {
        return this.processStatus === OtpStatus.Pending;
    }

    submit() {
        if (this.loading) {
            return false;
        }

        this.$emit('validate', this.code);

        return true;
    }

    get showError() {
        return this.processStatus === OtpStatus.InvalidOtp &&
            !this.loading;
    }

    resend(e) {
        e.preventDefault();

        if (this.loading) {
            return;
        }

        if (this.getRemainingTime() > 0) {
            this.disableResend();
            return;
        }

        this.disableResend();

        this.$emit('resend');
    }

    disableResend() {
        this.isResendEnabled = false;
    }

    enableResend() {
        this.isResendEnabled = true;
    }

    get invalidOtp() {
        return this.processStatus === OtpStatus.InvalidOtp;
    }

    get otpStyle() {
        return {
            'otp-invalid': this.invalidOtp && !this.loading,
            'otp-valid': !this.invalidOtp && !this.loading,
            'otp-loading': this.loading
        };
    }

    getRemainingTime() {
        const remainingTime = (this.requestThreshold - (dayjs(new Date()).valueOf() - this.timestamp));

        return remainingTime > 0 ? remainingTime : 0;
    }
}
