import i18next from 'i18next';

export default {
    async hasResource(lang: string, namespace: string) {
        return i18next.hasResourceBundle(lang, namespace);
    },

    async loadResources(namespaces: string | Array<string>) {
        const promise = new Promise<void>((resolve) => {
            i18next.loadNamespaces(namespaces, () => {
                resolve();
            });
        });

        return promise;
    }
};
