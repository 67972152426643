export const enum BrowserNames {
    Brave = 0,
    Chrome = 1,
    Chromium = 2,
    Edge = 3,
    Firefox = 4,
    Opera = 5,
    Safari = 6,
    Seamonkey = 7
}
