import bundle from '@/utilities/bundle';

export const DEVICE_ENROLLMENT_START_ROUTE = {
    path: '/',
    name: 'device-enrollment-start',
    component: bundle.loadView('device-enrollment/start/start'),
    props: true,
    meta: {
        title: 'deviceEnrollment.pageTitle'
    }
};

export const DEVICE_ENROLLMENT_PAIR_ROUTE = {
    path: '/pair',
    name: 'device-enrollment-pair',
    component: bundle.loadView('device-enrollment/pair/pair'),
    props: true,
    meta: {
        title: 'deviceEnrollment.pageTitle'
    }
};

export const DEVICE_ENROLLMENT_HELP_ROUTE = {
    path: '/help',
    name: 'device-enrollment-help',
    component: bundle.loadView('device-enrollment/help/help'),
    props: true,
    meta: {
        title: 'deviceEnrollment.pageTitle'
    }
};

export const DEVICE_ENROLLMENT_ROUTES = {
    path: '/account/device-enrollment',
    children: [
        DEVICE_ENROLLMENT_START_ROUTE,
        DEVICE_ENROLLMENT_PAIR_ROUTE,
        DEVICE_ENROLLMENT_HELP_ROUTE
    ]
};
