import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';

import { IdentityService } from '@/services/identity-service';
import { authenticationModeIds } from '@/constants/authentication-mode-ids';
import { authenticationIntentIds } from '@/constants/authentication-intent-ids';
import { TwoFactorEventId, TwoFactorMethodType, TwoFactorStatus } from '@/constants/two-factor';
import Countdown from '@/components/countdown/countdown.vue';
import { OtpMethod, PushMethod } from '@/components/two-factor/interfaces';
import { getToken } from '@/utilities/grecaptcha';
import { delay } from '@/core/helpers/delay';

@Component({
    data() {
        return {
            status: TwoFactorStatus.Pending,
            otpMethod: {
                clickable: false,
                visible: false,
                time: 0
            },
            pushMethod: {
                visible: false,
                requestTimeOut: false,
                updatedTime: 0
            },
            smsAuthDisabled: false
        };
    },
    components: {
        Countdown
    }
})
export default class TwoFactor extends Vue {
    @Prop()
    returnUrl: string;

    @Prop(Boolean)
    rememberMe: boolean;

    @Prop(Number)
    loginTimeout: number;

    status: TwoFactorStatus;
    otpMethod: OtpMethod;
    pushMethod: PushMethod;
    smsAuthDisabled: boolean;

    async open() {
        const remainingTime = 20_000 - (dayjs(new Date()).valueOf() - parseInt(Vue.$localStorage.getItem('timestamp') as any));

        this.otpMethod.time = remainingTime > 0 ? remainingTime : 1;

        this.status = TwoFactorStatus.Pending;
        this.pushMethod.visible = true;

        this.getRemainingTimeTwoFA();

        await this.verify();
    }

    async otpMethodFallback() {
        this.smsAuthDisabled = true;

        const tokenResult = await getToken(config.googleRecaptchaSiteKeyId);

        if (!tokenResult.success) {
            this.smsAuthDisabled = false;

            return;
        }

        const sessionResult = await IdentityService.createSession({
            recaptcha: tokenResult.data.token,
            intent: authenticationIntentIds.Accounts,
            modeId: authenticationModeIds.OneTimePassword
        });

        if (!sessionResult.success) {
            this.twoFaEventIdHandler(+sessionResult.data.eventId);

            this.smsAuthDisabled = false;

            return;
        }

        this.createStorageForRefresh(sessionResult.data);
        this.pushMethod.visible = false;
        this.otpMethod.visible = false;
        this.smsAuthDisabled = false;

        this.$emit('fallback');
    }

    async verify() {
        const twoFaRequest = await IdentityService.requestTwoFa({ rememberMe: this.rememberMe });

        if (!twoFaRequest.success) {
            if (twoFaRequest.invalid ||
                +twoFaRequest.data.eventId === TwoFactorEventId.RequestPending) {
                await delay(1000);

                await this.verify();

                return;
            }

            this.twoFaEventIdHandler(+twoFaRequest.data.eventId);

            return;
        }

        this.clearTwoFaStorage();

        window.location.href = this.returnUrl;
    }

    async retry() {
        const tokenResult = await getToken(config.googleRecaptchaSiteKeyId);

        if (!tokenResult.success) {
            return;
        }

        Vue.$localStorage.setItem('timestamp', String(dayjs(new Date()).valueOf()));
        this.status = TwoFactorStatus.Pending;
        this.pushMethod.visible = true;
        this.otpMethod.clickable = false;
        this.otpMethod.visible = false;
        this.pushMethod.requestTimeOut = false;

        const sessionResult = await IdentityService.createSession({
            recaptcha: tokenResult.data.token,
            intent: authenticationIntentIds.Accounts
        });

        if (!sessionResult.success) {
            this.status = TwoFactorStatus.Rejected;
            this.clearTwoFaStorage();

            return;
        }

        this.createStorageForRefresh(sessionResult.data);
        this.getRemainingTimeTwoFA();

        await this.verify();
    }

    get requestStatusValidity() {
        return this.status === TwoFactorStatus.Pending;
    }

    goBack() {
        this.getRemainingTimeTwoFA();
        this.otpMethod.visible = false;
    }

    createStorageForRefresh(data:any) {
        Vue.$localStorage.setItem(String(TwoFactorMethodType.Push), JSON.stringify(data));
    }

    twoFaEventIdHandler(eventId: number) {
        switch (eventId) {
            case TwoFactorEventId.RequestRejected:
                this.status = TwoFactorStatus.Rejected;
                this.clearTwoFaStorage();

                break;
            case TwoFactorEventId.RequestTimeout:
            case TwoFactorEventId.ValidateSessionFailed:
                this.pushMethod.requestTimeOut = true;
                this.otpMethod.visible = false;
                this.clearTwoFaStorage();

                break;
            default:
                if (eventId !== TwoFactorEventId.RequestAbortedForOtherMethod) {
                    this.status = TwoFactorStatus.Rejected;
                    this.clearTwoFaStorage();
                }

                break;
        }
    }

    getRemainingTimeTwoFA() {
        const remainingTime = this.getTimerDuration(TwoFactorMethodType.Push) - (dayjs(new Date()).valueOf() - parseInt(Vue.$localStorage.getItem('timestamp') as any));

        this.pushMethod.updatedTime = remainingTime > 0 ? remainingTime : 0;
    }

    clearTwoFaStorage() {
        Vue.$localStorage.removeItem(String(TwoFactorMethodType.Push));
    }

    clickOtherMethod() {
        this.pushMethod.visible = true;
        this.otpMethod.visible = true;
    }

    otherMethodSelectionVisible() {
        this.otpMethod.clickable = true;
    }

    getTimerDuration(mode: number) {
        return JSON.parse(Vue.$localStorage.getItem(String(mode)) as any).duration * 1000;
    }

    timeoutTimer() {
        return this.pushMethod.updatedTime > 0 ? this.pushMethod.updatedTime : (this.loginTimeout * 1000);
    }
}
