import i18next from 'i18next';
import Http from 'i18next-http-backend';
import { CulturePlugin, CulturePluginOptions } from '../interfaces';

declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $culture: CulturePlugin
    }
}

export default class VueCulture {
    static install(Vue, options: CulturePluginOptions) {
        Vue.$culture = {
            lang: null,
            id: options.id,
            i18nextOptions: options.i18nextOptions,
            localeSettings: options.localeSettings,
            localeAlias: options.localeAlias,
            countryLanguage: options.countryLanguage,

            getSettings(lang: string) {
                return this.localeSettings[lang] || this.localeSettings[options.defaultLang];
            },

            filterLang(lang: string) {
                lang = lang.toLowerCase();

                if (this.localeSettings[lang]) {
                    return lang;
                }

                if (this.localeAlias && this.localeAlias[lang]) {
                    return this.localeAlias[lang];
                }

                return options.defaultLang;
            },

            getLang() {
                return this.lang
                    ? this.filterLang(this.lang)
                    : options.defaultLang;
            },

            getCulture() {
                return this.localeSettings[this.lang]?.culture;
            },

            async getCountryLang(countryCode: string) {
                if (!this.countryLanguage || !countryCode) {
                    return null;
                }

                return this.countryLanguage[countryCode];
            },

            async setLang(lang: string) {
                if (this.lang && this.lang === lang) {
                    return;
                }

                this.lang = this.filterLang(lang);

                const settings = this.getSettings(this.lang);

                this.i18nextOptions.lng = settings.culture;

                await i18next
                    .use(Http)
                    .init(this.i18nextOptions);
            }
        };
    }
}
