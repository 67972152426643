import Service from '@/core/services/service';
import api from './config';

export default {
    findCountry() {
        const services = new Service(api);
        const url = '/geolocation/v1/country';

        return services.getData(url);
    }
};
