import { MdIcon } from '../components';

export function withMdIconComponent(iconSvg: string) {
    return {
        component: MdIcon,
        props: {
            icon: iconSvg
        }
    };
}
