import { Store } from 'vuex';
import { Database } from '@vuex-orm/core';

import Navigation from './models/navigation';
import navigation from './modules/navigation';

import User from './models/user';
import user from './modules/user';

import UserAuth from './models/userAuth';
import userAuth from './modules/userAuth';

import * as Models from './models';
import * as Modules from './modules';

const database = new Database();

database.register(Navigation, navigation);
database.register(User, user);
database.register(UserAuth, userAuth);

/**
 * Invitation
 */
database.register(Models.Invitation, Modules.invitation);
database.register(Models.InvitationAddress, Modules.invitationAddress);
database.register(Models.InvitationClient, Modules.invitationClient);
database.register(Models.InvitationUI, Modules.invitationUI);
database.register(Models.InvitationUserAuth, Modules.invitationUserAuth);

export interface PersistEntities {
    user: User,
    navigation: Navigation,
    invitation: Models.Invitation,
    invitationClient: Models.InvitationClient,
    invitationUI: Models.InvitationUI,
    invitationAddress: Models.InvitationAddress
}

export interface State extends Store<any>{
    entities: PersistEntities
}

export default database;
