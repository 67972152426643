import { Component, Vue, Watch } from 'vue-property-decorator';
import { BrowserNames } from '@/constants/browsers';
import { identifyBrowser } from '@/core/helpers/identify-browser';

import { retry } from '@/core/helpers/retry';

@Component({
    data() {
        return {
            filled: false
        };
    }
})
export class AutofillMixin extends Vue {
    filled: boolean;

    async mounted() {
        if (identifyBrowser(BrowserNames.Chromium)) {
            await retry(this.detectAutofill, 2, 500);
        }
    }

    detectAutofill() {
        const element: HTMLInputElement | null = this.$el.querySelector('input:-webkit-autofill');

        if (element) {
            this.filled = true;
        } else {
            throw new Error('element not found');
        }
    }

    @Watch('$attrs.value')
    onChangeInputValue(value: string) {
        if (!value) {
            this.filled = false;
        }
    }
}
