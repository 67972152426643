import { Language } from '@/core/i18n/interfaces';
import {
    LOCALES,
    Languages,
    LOCALE_SETTINGS
} from '@/core/i18n/constants';

export const LANGUAGE_ORDER = [
    LOCALES.English,
    LOCALES.Deutsch,
    LOCALES.French,
    LOCALES.Italian,
    LOCALES.Spanish,
    LOCALES.Polish,
    LOCALES.Romanian,
    LOCALES.Dutch,
    LOCALES.Greek,
    LOCALES.Czech,
    LOCALES.Portuguese,
    LOCALES.Hungarian,
    LOCALES.Bulgarian,
    LOCALES.Danish,
    LOCALES.Finnish,
    LOCALES.Croatian,
    LOCALES.Swedish,
    LOCALES.Norwegian
];

export const LANGUAGES: Array<Language> = [
    Languages.BULGARIAN_LANGUAGE,
    Languages.CROATIAN_LANGUAGE,
    Languages.CZECH_LANGUAGE,
    Languages.DANISH_LANGUAGE,
    Languages.DEUTSCH_LANGUAGE,
    Languages.DUTCH_LANGUAGE,
    Languages.ENGLISH_LANGUAGE,
    Languages.FINNISH_LANGUAGE,
    Languages.FRENCH_LANGUAGE,
    Languages.GREEK_LANGUAGE,
    Languages.HUNGARIAN_LANGUAGE,
    Languages.ITALIAN_LANGUAGE,
    Languages.POLISH_LANGUAGE,
    Languages.PORTUGUESE_LANGUAGE,
    Languages.SPANISH_LANGUAGE,
    Languages.ROMANIAN_LANGUAGE,
    Languages.SWEDISH_LANGUAGE,
    Languages.NORWEGIAN_LANGUAGE
];

export const DEFAULT_LANG = LOCALES.English;

export const LOCALIZE_OPTIONS = {
    lang: DEFAULT_LANG,
    culture: LOCALE_SETTINGS[DEFAULT_LANG].culture,
    path: '/locales/{{lng}}/{{ns}}.json'
};
