export const enum TwoFactorStatus {
    Pending = 0,
    Rejected = 1
}

export const enum TwoFactorMethodType {
    Otp = 1,
    Push = 2
}

export const enum TwoFactorEventId {
    ValidateSessionFailed = 12119,
    RequestRejected = 100861,
    RequestPending = 100862,
    RequestTimeout = 100863,
    RequestAbortedForOtherMethod = 100864
}
