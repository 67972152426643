declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $sessionStorage: Storage
    }
}

export default class VueSessionStorage {
    static install(Vue, options?: any) {
        Vue.$sessionStorage = {
            hasSessionStorage():boolean {
                return window.sessionStorage != null;
            },
            getItem(key: string) {
                if (!this.hasSessionStorage()) {
                    return null;
                }

                return window.sessionStorage.getItem(key);
            },
            setItem(key: string, value: any) {
                if (!this.hasSessionStorage()) {
                    return;
                }

                window.sessionStorage.setItem(key, value);
            },

            removeItem(key: string) {
                if (!this.hasSessionStorage()) {
                    return;
                }

                window.sessionStorage.removeItem(key);
            }
        };
    }
}
