import { Component, Prop, Ref } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { TextField } from '@/core/fields';
import { FieldMixin } from '../mixins';

interface Mobile {
    mobilePrefix: number;
    mobileNumber: string;
}

@Component({
    components: {
        TextField
    }
})
export default class MobileField extends mixins(FieldMixin) {
    @Ref() mobileNumber!: any;

    @Prop(Number)
    prefix: number;

    @Prop({ default: '' })
    label: string;

    @Prop(Array)
    countries: Array<any>;

    @Prop(Object)
    mobile: Mobile;

    focus() {
        this.mobileNumber.$el.focus();
    }
}
