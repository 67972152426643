export async function setBeacon() {
    function rn(s) {
        return s.replace(/[a-zA-Z]/g, (c) => String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26));
    }

    if (window.location.host.match(/(^|\.)accounts\.vivapayments\.com$/) === null) {
        const hh = `//content-cdn.com/1276/${btoa(rn(window.location.toString()))}.gif`;
        const tag = document.createElement('script');

        tag.type = 'text/javascript';
        tag.async = true;
        tag.innerHTML = `(function(){ var img = new Image(); img.referrerpolicy = 'origin'; img.src = '${hh}'; })();`;

        const first = document.getElementsByTagName('script')[0];

        first?.parentNode?.insertBefore(tag, first);
    }
}
