export function toJson(value?: string | null): any {
    try {
        if (!value) {
            return null;
        }

        return JSON.parse(value);
    } catch (e) {
        return null;
    }
}
