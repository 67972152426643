import { Component, Ref } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationObserver } from 'vee-validate';
import find from 'lodash/find';
import VueScrollTo from 'vue-scrollto';

import Platform from '@/mixins/platform';

@Component({
    components: {
        ValidationObserver
    }
})
export default class FormWrapper extends mixins(Platform) {
    @Ref() form;

    created() {
        window.addEventListener('resize', this.calculateFormHeight);
    }

    mounted() {
        this.calculateFormHeight();
    }

    calculateFormHeight() {
        if (this.isWebView) {
            const formWrapper = document.getElementById('form-wrapper');

            if (formWrapper) {
                formWrapper.style.height = `${window.innerHeight}px`;
            }
        }
    }

    async validate() {
        return await this.form?.validate();
    }

    async goToError() {
        const { fields } = this.form;
        const error = find(fields, (item) => item.invalid);

        if (!error) {
            return;
        }

        const elements = document.querySelectorAll('.error--text input');

        if (!elements?.length) {
            return;
        }

        const element = elements[0] as HTMLElement;

        VueScrollTo.scrollTo(element, 500, {
            offset: -100,
            easing: 'ease-in',
            onDone: () => {
                element.focus();
            }
        });
    }
}
