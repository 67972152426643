import { Model } from '@vuex-orm/core';

import { RegistrationSystem } from '@/constants/registration-system';
import { InvitationClient } from './invitation-client';
import { InvitationUI } from './invitation-ui';
import { InvitationUserAuth } from './invitation-user-auth';

export class Invitation extends Model {
    static entity = 'invitation';

    static fields() {
        return {
            id: this.attr(null),
            client: this.morphOne(InvitationClient, 'modelId', 'modelType'),
            userAuth: this.morphOne(InvitationUserAuth, 'modelId', 'modelType'),
            ui: this.morphOne(InvitationUI, 'modelId', 'modelType'),
            registrationSystem: this.attr(RegistrationSystem.Default),
            // registrationSource: this.attr(RegistrationSource.Online)
        };
    }

    get userFullName() {
        return `${this.user?.firstName} ${this.user?.lastName}`.trim();
    }
}
