import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import sortBy from 'lodash/sortBy';

import Constants from '@/constants';
import { DEFAULT_LANG } from '@/constants/i18n';
import { Resources, Validator } from '../utilities';

@Component
export default class Culture extends Vue {
    async importLanguage() {
        /**
         * Set language from url query parameter
         */
        let lang: string | null = this.sanitizeLang(this.$route.query.lang?.toString());

        if (lang) {
            await this.setLang(lang);

            return;
        }

        /**
         * Set language from local storage
         */
        lang = this.sanitizeLang(Vue.$localStorage.getItem('language') || '');

        if (lang) {
            await this.setLang(lang);

            return;
        }

        /**
         * Set language from country code
         */
        if (!lang) {
            const countryCode = localStorage.getItem('countryCode') || '';

            await this.setCountryLang(countryCode);
        }

        /**
         * Set default language
         */
        if (!this.getStoredLang()) {
            await this.setLang(DEFAULT_LANG);
        }
    }

    getLang() {
        return Vue.$culture.getLang();
    }

    sanitizeLang(lang: string | null) {
        if (!lang) {
            return '';
        }

        const matches: RegExpMatchArray | null = lang.match(/^([a-z]{2})-([a-z]{2})$|^([a-z]{2}$)/i);

        if (!matches) {
            return '';
        }

        const [, langCountryGroup, , langGroup, ...more] = matches;

        return langCountryGroup || langGroup || '';
    }

    getStoredLang() {
        return Vue.$localStorage.getItem('language');
    }

    async applyLang(lang: string) {
        await Vue.$culture.setLang(lang);
        await this.loadErrorResources(lang);
    }

    async setLang(lang: string) {
        await this.applyLang(lang);

        Vue.$localStorage.setItem('language', lang);
    }

    async loadErrorResources(lang: string) {
        await Validator.loadErrorResources(lang);
    }

    async setCountryLang(countryCode: string) {
        if (!countryCode) {
            return;
        }

        const lang = await Vue.$culture.getCountryLang(countryCode);

        if (lang) {
            await this.setLang(lang);
        }
    }

    async hasResource(namespace: string, lang: string = this.getLang()) {
        return await Resources.hasResource(lang, namespace);
    }

    async loadResources(namespace: string | Array<string>) {
        await Resources.loadResources(namespace);
    }

    getSortedLanguages() {
        return sortBy(Constants.i18n.LANGUAGES,
            (language) => Constants.i18n.LANGUAGE_ORDER.indexOf(language.value));
    }
}
