import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import createPersistedState from 'vuex-persistedstate';

import { NavigationOptions, StateOptions } from '@/constants';
import database, { State } from './database';
import {
    invitationCreatePersist
} from './persist';

Vue.use(Vuex);

export const store = () => new Vuex.Store({
    plugins: [
        VuexORM.install(database),
        invitationCreatePersist().plugin,
        createPersistedState({
            key: StateOptions.vuex,
            reducer: (state: State) => {
                Vue.$localStorage.setItem(`${StateOptions.vuex}-creation`, `${new Date()}`);

                return {
                    entities: {
                        user: state.entities.user
                    }
                };
            }
        }),
        createPersistedState({
            key: NavigationOptions.vuex,
            reducer: (state: State) => {
                Vue.$localStorage.setItem(`${NavigationOptions.vuex}-creation`, `${new Date()}`);

                return {
                    entities: {
                        navigation: state.entities.navigation
                    }
                };
            }
        })
    ]
});
