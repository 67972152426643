import { Model } from '@vuex-orm/core';

export class InvitationUI extends Model {
    static entity = 'invitationUI';

    static fields() {
        return {
            id: this.attr(null),
            isSsoConnected: this.boolean(false),
            preRegister: this.boolean(false),
            postRegister: this.boolean(false),
            mobile: this.attr(null),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }
}
