import { Component, Vue } from 'vue-property-decorator';
import { SsoProviderService } from '@/services/sso-service';

@Component({})
export default class ConnectLogin extends Vue {
    returnUrl: string;

    async mounted() {
        this.returnUrl = Vue.$localStorage.getJsonItem('returnUrl') || '';

        const result = await SsoProviderService.login({
            returnUrl: this.returnUrl
        });

        Vue.$sessionStorage.setItem('ssoResult', JSON.stringify(result));
        this.goToLoginPage();
    }

    goToLoginPage() {
        this.$router.push({ name: 'login' });
    }
}
