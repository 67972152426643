import { render, staticRenderFns } from "./login.vue?vue&type=template&id=5687f85e&scoped=true"
import script from "./login.ts?vue&type=script&lang=ts&external"
export * from "./login.ts?vue&type=script&lang=ts&external"
import style0 from "./login.scss?vue&type=style&index=0&id=5687f85e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5687f85e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { CookiesBlockedAlert as VivaAppCookiesBlockedAlert } from '@/components'
import { Page as VivaAppPage } from '@/components'
import { Loader as VivaLoader } from '@/core/components'
import { MobilePrefixSelectField as VivaMobilePrefixSelectField } from '@/core/components'
import { PasswordField as VivaPasswordField } from '@/core/components'
import { TextField as VivaTextField } from '@/core/components'
installComponents(component, {VBtn,VCheckbox,VivaAppCookiesBlockedAlert,VivaAppPage,VivaLoader,VivaMobilePrefixSelectField,VivaPasswordField,VivaTextField})
