import { LOCALES } from '@/core/i18n/constants/locales';

export const LOCALE_SETTINGS = {
    [LOCALES.Bulgarian]: {
        culture: 'bg',
        dateFormat: 'bg'
    },
    [LOCALES.Croatian]: {
        culture: 'hr',
        dateFormat: 'hr'
    },
    [LOCALES.Czech]: {
        culture: 'cs',
        dateFormat: 'cs'
    },
    [LOCALES.Danish]: {
        culture: 'da',
        dateFormat: 'da'
    },
    [LOCALES.Deutsch]: {
        culture: 'de',
        dateFormat: 'de'
    },
    [LOCALES.Dutch]: {
        culture: 'nl',
        dateFormat: 'nl'
    },
    [LOCALES.English]: {
        culture: 'en',
        dateFormat: 'en-gb'
    },
    [LOCALES.Finnish]: {
        culture: 'fi',
        dateFormat: 'fi'
    },
    [LOCALES.French]: {
        culture: 'fr',
        dateFormat: 'fr'
    },
    [LOCALES.Greek]: {
        culture: 'el',
        dateFormat: 'el'
    },
    [LOCALES.Hungarian]: {
        culture: 'hu',
        dateFormat: 'hu'
    },
    [LOCALES.Italian]: {
        culture: 'it',
        dateFormat: 'it'
    },
    [LOCALES.Norwegian]: {
        culture: 'nb',
        validator: 'nb_NO',
        dateFormat: 'nb'
    },
    [LOCALES.Polish]: {
        culture: 'pl',
        dateFormat: 'pl'
    },
    [LOCALES.Portuguese]: {
        culture: 'pt',
        validator: 'pt_PT',
        dateFormat: 'pt'
    },
    [LOCALES.Romanian]: {
        culture: 'ro',
        dateFormat: 'ro'
    },
    [LOCALES.Spanish]: {
        culture: 'es',
        dateFormat: 'es'
    },
    [LOCALES.Swedish]: {
        culture: 'sv',
        dateFormat: 'sv'
    }
};
