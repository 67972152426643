import { Component, Prop, VModel } from 'vue-property-decorator';

import { Country } from '@/core/countries/interfaces';
import { matchCountryByLocalName, matchCountryByPrefix } from '@/core/countries/helpers';
import { i18n } from '@/core/i18n';
import SelectField from '../select-field';

@Component({
    data() {
        return {
            searchText: '',
            modalOpened: false
        };
    },
    components: {
        SelectField
    }
})
export default class MobilePrefixSelectField extends i18n.Mixins.Culture {
    @VModel()
    countryCode: string;

    @Prop(Array)
    countries: Array<Country>;

    @Prop(Boolean)
    allowFilter: boolean;

    @Prop(Boolean)
    modalView: boolean;

    searchText: string;
    modalOpened: boolean;

    get options() {
        return this.searchText
            ? this.countries.filter((country) => this.customFilter(country, this.searchText))
            : this.countries;
    }

    customFilter(item, queryText) {
        const localizedCountryName = this.$t(`countries:${item.code}.name`);

        return matchCountryByLocalName(localizedCountryName, queryText) ||
            matchCountryByPrefix(item, queryText);
    }
}
