import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=2fc1f9fe&scoped=true"
import script from "./checkbox.ts?vue&type=script&lang=js&external"
export * from "./checkbox.ts?vue&type=script&lang=js&external"
import style0 from "./checkbox.scss?vue&type=style&index=0&id=2fc1f9fe&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc1f9fe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
