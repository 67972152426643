import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import find from 'lodash/find';

import { i18n } from '@/core/i18n';
import { Language } from '@/core/i18n/interfaces';

@Component({
    data() {
        return {
            language: '',
            showModal: false
        };
    }
})
export default class LanguageMenu extends mixins(i18n.Mixins.Culture) {
    @Prop(Boolean)
    fullText: boolean;

    @Prop(Boolean)
    modalView: boolean;

    @Prop(Array)
    languages: Array<Language>;

    @Prop(Boolean)
    hideName: boolean;

    language: string;
    showModal: boolean;

    mounted() {
        this.language = this.getLang();
    }

    get languageName() {
        const culture = find(this.languages, { value: this.language });

        return culture?.text || this.language;
    }

    async change(lang: string) {
        await this.setLang(lang);

        this.language = this.getLang();

        this.showModal = false;
    }
}
